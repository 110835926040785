import { forwardRef } from 'react';

import { DatePicker } from '@material-ui/pickers';

const DateInput = forwardRef(
  ({ handleEnter, handleKey, variant = 'outlined', ...rest }, ref) => (
    <DatePicker
      {...rest}
      inputRef={ref}
      autoFocus={false}
      onKeyDown={(e) => {
        if (e.keyCode === 13) handleEnter();
        else handleKey(e.keyCode, e.key);
      }}
    />
  ),
);

export default DateInput;
