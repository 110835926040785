import NumberFormat from 'react-number-format';

function NumberFormatInput(props) {
  const {
    inputRef,
    onChange,
    formato,
    escala,
    sufixo,
    prefixo,
    ...other
  } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format={formato}
      allowEmptyFormatting={formato !== null}
      mask="_"
      thousandSeparator="."
      decimalSeparator=","
      prefix={prefixo}
      suffix={sufixo}
      decimalScale={escala}
      fixedDecimalScale
      isNumericString
    />
  );
}

export default NumberFormatInput;
