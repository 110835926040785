import { forwardRef } from 'react';

import { TextField } from '@material-ui/core';

import NumberFormatInput from './NumberFormatInput';

const TemplateInput = forwardRef(
  (
    {
      label,
      value,
      onChange,
      fullWidth,
      disabled = false,
      error = false,
      helperText = '',
      handleEnter = () => {},
      formato = null,
      escala = 0,
      sufixo = '',
      prefixo = '',
      handleKey = () => {},
      toString2 = false,
      variant = 'outlined',
      InputProps = {},
      styleInput = {},
      ...rest
    },
    forwardedRef,
  ) => (
    <TextField
      margin="normal"
      {...rest}
      label={label}
      type="text"
      value={value}
      variant={variant}
      fullWidth={fullWidth}
      disabled={disabled}
      onChange={(e) => {
        onChange(e);
      }}
      InputProps={{
        inputComponent: NumberFormatInput,
        ...InputProps,
      }}
      inputProps={{
        formato,
        escala,
        sufixo,
        prefixo,
        style: styleInput,
      }}
      error={error}
      helperText={helperText}
      inputRef={forwardedRef}
      onKeyDown={(e) => {
        if (e.keyCode === 13) handleEnter();
        else handleKey(e.keyCode, e.key);
      }}
    />
  ),
);

export default TemplateInput;
