import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import {
  createStyles,
  lighten,
  makeStyles,
} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Switch from '@material-ui/core/Switch';
import moment from 'moment';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CheckIcon from '@material-ui/icons/Check';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import Avatar from '@material-ui/core/Avatar';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { SnackbarProvider , useSnackbar } from 'notistack';
import PrintIcon from '@material-ui/icons/Print';
import { useDebounce } from 'use-debounce';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ptBR } from 'date-fns/locale';
import RefreshIcon from '@material-ui/icons/Refresh';
import api, { getListAPI, getListAllAPI , updateAPI } from './services/api';
import background from './assets/background.jpg';
import DialogoChangeStatus from './components/Dialogs/DialogoChangeStatus';
import DialogoDeletar from './components/Dialogs/DialogoDeletar';
import DialogoEdit from './components/Dialogs/DialogoEdit';
import TextInput from './components/inputs/TextInput'
import DateInputMobills from './components/inputs/DateInputMobills'
import DialogoChangeStatusSelect from './components/Dialogs/DialogoChangeStatusSelect';
import socketIOClient from "socket.io-client";
import { ThemeProvider } from '@material-ui/core';
import theme from './themes/default';
import { URL_DRIVE_IMPRESSORA_ETIQUETAS, APP_VERSION, APP_VERSION_FUNCIONALIDADES } from './utils'
const ENDPOINT = "http://127.0.0.1:4001";

function createData(
  nome,
  horario,
  copias,
  status,
  id
) {
  return { nome, horario, copias, status, id };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'nome',
    numeric: false,
    disablePadding: false,
    label: 'Produto',
  },
  { id: 'horario', numeric: true, disablePadding: false, label: 'Horário' },
  { id: 'copias', numeric: true, disablePadding: false, label: 'Cópias' },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler =
    (property) => (event) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell
          key="actions"
          align="right"
          padding="normal"
          sortDirection={false}
        >
          <TableSortLabel active={false} direction="asc" onClick={() => {}}>
            Ações
          </TableSortLabel>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: '1 1 100%',
    },
  })
);

const EnhancedTableToolbar = ({ numSelected, handleChangeStatus }) => {
  const classes = useToolbarStyles();

  return (
    <>
      { numSelected > 0 ? <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selecionados
        </Typography>
        <Tooltip title="Delete">
            <IconButton aria-label="delete" onClick={handleChangeStatus}>
              <CheckIcon />
            </IconButton>
          </Tooltip>
      </Toolbar> : null}
    </>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: '20px 20px',
      display: 'flex',
      flexDirection: 'column',
    },
    root2: {
      width: '100%',
      height: '100vh',
      padding: '20px 20px',
      backgroundImage: `url(${background})`,
      backgroundSize: 'cover',
      display: 'flex',
      flexDirection: 'column',
      position: 'fixed',
      zIndex: -1000
    },
    paper: {
      width: '100%',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    pendente: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      color: 'white',
      backgroundColor: '#f44336',
      cursor: 'pointer',
    },
    confirmado: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      color: 'white',
      backgroundColor: '#4caf50',
      cursor: 'pointer',
    },
  })
);

const DIA_ATUAL = new Date().getDate();
const MES_ATUAL = new Date().getMonth();
const ANO_ATUAL = new Date().getFullYear();


function buildData(dia, mes, ano) {
  return `${dia < 10 ? `0${dia}` : dia}${mes < 10 ? `0${mes}` : mes}${ano}`;
}

function pause(segundos = 1) {
  return new Promise((resolve) => 
    setTimeout(() => resolve(), segundos * 1000)
  )
}

function EnhancedTable() {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('nome');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [users, setUsers] = React.useState([]);
  const [id, setId] = React.useState(-1);
  const [impressos, setImpressos] = React.useState(false);
  const refDialogoEdit = useRef(null);
  const refDialogoDeletar = useRef(null);
  const refDialogoChangeStatus = useRef(null);
  const refDialogoChangeStatusSelect = useRef(null);
  const [imprimindo, setImprimindo] = React.useState(false)
  const [search, setSearch] = React.useState('')
  const [searchDebounce] = useDebounce(search, 500);
  const [data2, setData2] = React.useState(buildData(DIA_ATUAL, MES_ATUAL, ANO_ATUAL));
  const { enqueueSnackbar } = useSnackbar();
  const [socket, setSocket] = React.useState(null);

  useEffect(() => {
    async function getForms () {
      const data = await api.get(`formularios_impressora_etiquetas`)
      socket.emit('enviar_templates', { idFila: id, forms: data.data} )
    }
    if(imprimindo) {
      getForms ()
    }
  }, [imprimindo])

  useEffect(() => {
    const socket = socketIOClient(URL_DRIVE_IMPRESSORA_ETIQUETAS);
   
    socket.on("handle_imprimir_etiquetas", async({ codigo, nomeProduto, idImpressao, idFila }) => {
      await pause(2)
      switch (codigo) {
        case 0: {
          await getImpressoes(idFila, socket)
          break;
        }
        case 1: {
          enqueueSnackbar(`${nomeProduto} impresso com sucesso!`, {
            variant: 'success',
          });
          await updateAPI(
            'solicitacoes_impressoes_etiqueta',
            idImpressao,
            {
              status: true,
              horario: new Date()
            }
          )
          await getImpressoes(idFila, socket)
          break;
        }
        case 2: {
          enqueueSnackbar(`${nomeProduto} impresso com sucesso!`, {
            variant: 'success',
          });
          await updateAPI(
            'solicitacoes_impressoes_etiqueta',
            idImpressao,
            {
              status: true,
              horario: new Date()
            }
          )
          await getImpressoes(idFila, socket)
          break;
        }
        default:
          break;
      }
    });
    setSocket(socket);
    return () => socket.disconnect();
  }, []);

  async function getImpressoes (idFila, socket) {
    try {
      const data = await api.get(`gerar_documentos_impressora_etiquetas/${idFila}`)
      const {
        content,
        id,
        produto_name
      } = data.data
      if(id > 0) {
        if(socket) {
          socket.emit('imprimir_etiqueta', { idFila, objImpressao: {
            content,
            produto_name,
            id
          }})
        } else {
          setImprimindo(false)
        }
      } else {
        enqueueSnackbar('Impressão concluída!', {
          variant: 'success',
        });
        setImprimindo(false)
        refresh()
      } 
    } catch(e) {
      enqueueSnackbar('Ocorreu um erro durante a impressão!', {
        variant: 'error',
      });
      setImprimindo(false)
      refresh()
    }
  };

  function getDataFormatada(data) {
    return {
      dia: parseInt(data.substring(0, 2), 10),
      mes: parseInt(data.substring(2, 4), 10),
      ano: parseInt(data.substring(4), 10),
    };
  }

  async function getData() {
    try {
      const data = await getListAPI(
        'solicitacoes_impressoes_etiqueta_2',
        ['id', 'asc'],
        [page + 1, rowsPerPage],
        impressos ? { status: impressos, user_id: id, nome: search, periodo: getDataFormatada(data2) } : { status: impressos, user_id: id , nome: search,  },
        ['produto', 'user']
      );
      const rowsLocal = [];
      for (let i = 0; i < data.data.length; i += 1) {
        const impressao = data.data[i];
        if(impressao.produto) {
          rowsLocal.push(
            createData(
              impressao.produto.nome,
              impressao.horario,
              impressao.copias,
              impressao.status ? 1 : 0,
              impressao.id
            )
          );
        }
      }
      setRows([]);
      setRows(rowsLocal);
      setTotal(data.total);
    } catch (erros) {
      enqueueSnackbar(`Erro ao buscar os registros!`, {
        variant: 'error',
      });
      setRows([]);
    }
  }

  useEffect(() => {
    getData()}
  , [page, rowsPerPage, id, impressos, searchDebounce, data2]);

  async function getUsuariosTodos() {
    try {
      const data = await getListAllAPI(
        'users',
        ['id', 'asc'],
        {},
        []
      );
      if (data.data.length > 0) {
        setUsers(formatUsers(data.data));
      } else {
        throw 'Nenhuma usuário disponível!';
      }
    } catch (erros) {
      enqueueSnackbar(`Nenhuma usuário disponível!`, {
        variant: 'error',
      });
      setUsers([])
    }
  }

  function updateUser() {
    if(impressos) {
      getUsuariosTodos()
    } else {
      getUsers()
    }
  }

  useEffect(() => {
    updateUser()
  }, [impressos]);

  const handleRequestSort = (
    event,
    property
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length);

  function formatUsers(usersLocal) {
    const usersFormat = [];
    for (let i = 0; i < usersLocal.length; i += 1) {
      usersFormat.push({
        value: usersLocal[i].id,
        label: usersLocal[i].username,
      });
    }
    return usersFormat;
  }

  async function getUsers() {
    try {
      const data = await getListAllAPI(
        'users_impressoes',
        ['id', 'asc'],
        {},
        []
      );
      if (data.data.length > 0) {
        setUsers(formatUsers(data.data));
      } else {
        throw 'Nenhuma fila disponível!';
      }
    } catch (erros) {
      enqueueSnackbar(`Nenhuma fila disponível!`, {
        variant: 'error',
      });
      setUsers([])
    }
  }

  const handleChange = (event) => {
    setId(parseInt(event.target.value, 10));
  };

  const handleChangeImpressos = (event) => {
    setImpressos(event.target.checked);
  };

  const edit = (id) => {
    if (refDialogoEdit.current) {
      refDialogoEdit.current.handleEdit(id);
    }
  };

  const changeStatus = (id) => {
    if (refDialogoChangeStatus.current) {
      refDialogoChangeStatus.current.handleOpen(id);
    }
  };

  const deletar = (id) => {
    if (refDialogoDeletar.current) {
      refDialogoDeletar.current.handleOpen(id);
    }
  };

  function findUser(users, id) {
    for(let i = 0; i < users.length; i+=1) {
      if(users[i].id === id) return true
    }
    return false
  }

  async function verifyUser() {
    try {
      const data = await getListAllAPI(
        'users_impressoes',
        ['id', 'asc'],
        {},
        []
      );
      if (data.data.length > 0) {
        /* setUsers(formatUsers(data.data)); */
        return findUser(data.data, id) 
      } 
      throw 'Nenhuma fila disponível!';
    } catch (erros) {
      enqueueSnackbar(`Nenhuma fila disponível!`, {
        variant: 'error',
      });
      setUsers([])
      setId(-1)
      return false
    }
  }

  async function imprimir () {
    const verificacao = await verifyUser()
    if(verificacao) setImprimindo(true)
  };

  const refresh = () => {
    updateUser()
    getData()
  };

  function handleChangeStatus() {
    if (refDialogoChangeStatusSelect.current) {
      refDialogoChangeStatusSelect.current.handleOpen(
        selected,
        impressos,
      );
    }
  }

  return (
    <>
    <div className={classes.root2} />
      <div className={classes.root} style={{ opacity: '0.6' }}>
        <div
          style={{
            marginBottom: '20px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Switch checked={impressos} onChange={handleChangeImpressos} />
          <TextInput 
            handleEnter={() => {}} 
            handleKey={() => {}} 
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            style={{ backgroundColor: 'white' }}
            label="Produto"
          />
          <div>
            <Select
              value={id}
              onChange={handleChange}
              variant="outlined"
              style={{ backgroundColor: 'white' }}
            >
              {users.map((element) => (
                <MenuItem value={element.value}>{element.label}</MenuItem>
              ))}
            </Select>
            { imprimindo ? <IconButton
              aria-label="edit"
              size="medium"
              style={{ backgroundColor: 'white', marginLeft: '20px' }}
              disabled
            >
              <PrintIcon />
            </IconButton> : <IconButton
              aria-label="edit"
              size="medium"
              onClick={imprimir}
              style={{ backgroundColor: 'white', marginLeft: '20px' }}
            >
              <PrintIcon />
            </IconButton>}
            <IconButton
              aria-label="edit"
              size="medium"
              onClick={refresh}
              style={{ backgroundColor: 'white', marginLeft: '20px' }}
            >
              <RefreshIcon />
            </IconButton>
          </div>
        </div>
        { impressos && <div
          style={{
            marginBottom: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <DateInputMobills dataSuperior={data2} aplicarFiltros={(dataNew) => setData2(dataNew)}/>
        </div>}
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <Paper className={classes.paper}>
            <EnhancedTableToolbar numSelected={selected.length} handleChangeStatus={handleChangeStatus}/> 
            <TableContainer style={{ overflowY: 'scroll' }}>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size='small'
                aria-label="enhanced table"
                stickyHeader
              >
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {stableSort(rows, getComparator(order, orderBy))
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row.id)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.status ? (
                              <Avatar className={classes.confirmado} onClick={() => changeStatus(row.id)}>
                                <CheckIcon style={{ fontSize: 15 }} />
                              </Avatar>
                            ) : (
                              <Avatar className={classes.pendente} onClick={() => changeStatus(row.id)}>
                                <PriorityHighIcon style={{ fontSize: 15 }} />
                              </Avatar>
                            )}
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                          >
                            {row.nome}
                          </TableCell>
                          <TableCell align="right">
                            {moment(row.horario).format('HH:mm')}
                          </TableCell>
                          <TableCell align="right">{row.copias}</TableCell>
                          <TableCell align="right">
                            <IconButton
                              aria-label="edit"
                              size="small"
                              onClick={() => edit(row.id)}
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              aria-label="delete"
                              size="small"
                              onClick={() => deletar(row.id)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              count={total}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Por página"
            />
          </Paper>
        </div>
      </div>
      <DialogoEdit ref={refDialogoEdit} handleClose={() => refresh()}/>
      <DialogoChangeStatus
        handleClose={() => refresh()}
        ref={refDialogoChangeStatus}
      />
      <DialogoDeletar  handleClose={() => getData()} ref={refDialogoDeletar} />
      <DialogoChangeStatusSelect
        handleClose={() => {
          refresh()
          setSelected([]);
        }}
        ref={refDialogoChangeStatusSelect}
      />
    </>
  );
}

function MensagemDesatualizado({ oldVersion, newVersion }) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        //background: 'url(/background.jpg)',
        //backgroundSize: 'cover',
        color: 'red',
      }}
    >
      <h1
        style={{
          textAlign: 'center',
        }}
      >
        Módulo de Etiquetas desatualizado, se quer voltar a usa-lo, por favor abre e feche
        algumas vezes até atualizar
      </h1>
      <h2
        style={{
          textAlign: 'center',
        }}
      >{`Versão Atual: ${oldVersion}`}</h2>
      <h2
        style={{
          textAlign: 'center',
        }}
      >{`Versão Nova: ${newVersion}`}</h2>
    </div>
  );
}

function App2() {
  const [version, setVersion] = useState(APP_VERSION);

  async function getVersion() {
    const response = await api.get('/versions');
    const { modulo_etiquetas } = response.data;

    setVersion(modulo_etiquetas);
  }

  useEffect(() => {
    getVersion();
  }, []);

  const isAtualizado = version === APP_VERSION;

  return (
    <>
      {isAtualizado ? (
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
            <SnackbarProvider maxSnack={6}>
              <EnhancedTable/>
            </SnackbarProvider>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      ) : (
        <MensagemDesatualizado oldVersion={APP_VERSION} newVersion={version} />
      )}
    </>
  );
}

export default App2;
